import React from "react";
import { Grid } from "theme-ui";
import Layout from "../components/layout";
import ThemeableHeader from "../components/layout/themeable-header";
import OpenTeaser from "../components/page-blocks/open-teaser";
import NewsletterSignup from "../components/page-blocks/newsletter-signup";
import TwitterCallToAction from "../components/page-blocks/twitter-cta";
import Seo from "../components/seo";
import { getFlatFields } from "../lib/text-helpers";
import { graphql } from "gatsby";

const mergePostsAndSortByDate = (arrayOne, arrayTwo) => {
  const masterArray = arrayOne
    .concat(arrayTwo)
    .sort((a, b) => {
      var dateA = a.post.dateGmt.toUpperCase();
      var dateB = b.post.dateGmt.toUpperCase();
      return dateA > dateB ? -1 : dateA < dateB ? 1 : 0;
    })
    .map((post) => post.post);
  return masterArray;
};

const PublicationsPage = ({ data: { publications, briefings } }) => {
  const allLatestPosts = mergePostsAndSortByDate(
    publications.edges,
    briefings.edges
  );
  return (
    <Layout>
      <Seo
        title="Publications"
        description="Briefings and research projects from the AQi"
      />
      <ThemeableHeader
        title={"Publications"}
        active="/publications"
        pageIntroduction={"Briefings and research projects from the AQi"}
      />
      <Grid as="section" columns={1} gap={[5, 6]} sx={{ my: [5, 6] }}>
        {allLatestPosts.map((post, index) => {
          const fields = getFlatFields(post);
          return (
            <OpenTeaser
              key={post.slug}
              mirror={index % 2 === 1}
              {...fields}
              src={post?.featuredImage?.node.mediaItemUrl}
            />
          );
        })}
        <TwitterCallToAction />
        <NewsletterSignup />
      </Grid>
    </Layout>
  );
};

export const query = graphql`
  {
    publications: allWpPublication(
      sort: { fields: dateGmt, order: DESC }
      filter: { additionalInfo: { hide: { ne: true } } }
    ) {
      edges {
        post: node {
          id
          dateGmt
          title
          slug
          postType: nodeType
          featuredImage {
            node {
              mediaItemUrl
            }
          }
          additionalInfo {
            standfirst
          }
          themeCategories {
            nodes {
              name
              slug
            }
          }
        }
      }
    }

    briefings: allWpBriefing(
      sort: { fields: dateGmt, order: DESC }
      filter: { additionalInfo: { hide: { ne: true } } }
    ) {
      edges {
        post: node {
          id
          postType: nodeType
          dateGmt
          title
          slug
          featuredImage {
            node {
              mediaItemUrl
            }
          }
          additionalInfo {
            standfirst
          }
          themeCategories {
            nodes {
              name
              slug
            }
          }
        }
      }
    }
  }
`;

export default PublicationsPage;
